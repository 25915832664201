import {
	SP_ORDERS_LIST_FILTERED,
	SP_ORDERS_LIST_FILTERED_SUCCESS,
	SP_ORDERS_LIST_FILTERED_ERROR,
	SP_ORDER_CREATE,
	SP_ORDER_CREATE_SUCCESS,
	SP_ORDER_CREATE_ERROR,
	SP_ORDER_DELETE,
	SP_ORDER_DELETE_SUCCESS,
	SP_ORDER_DELETE_ERROR,
	SP_ORDER_ARCHIVE,
	SP_ORDER_ARCHIVE_SUCCESS,
	SP_ORDER_ARCHIVE_ERROR,
	SP_ORDER_UNARCHIVE,
	SP_ORDER_UNARCHIVE_SUCCESS,
	SP_ORDER_UNARCHIVE_ERROR,
	SP_ORDER_GET,
	SP_ORDER_GET_SUCCESS,
	SP_ORDER_GET_ERROR,
	SP_ORDER_SET_VIEWED,
	SP_ORDER_SET_VIEWED_SUCCESS,
	SP_ORDER_SET_VIEWED_ERROR,
	USER_SP_ORDERS_BRANDS_FILTER,
	USER_SP_ORDERS_DEPARTMENT_FILTER,
	USER_SP_ORDERS_LOCATION_FILTER,
	USER_SP_ORDERS_STATUS_FILTER,
	USER_SP_ORDERS_SORTING
} from "../actions/spOrders";
import Vue from "vue";
import SpOrdersService from '@/services/spOrders';

const state = {
	status: '',
	userSpOrdersBrandsFilter: null,
	userSpOrdersDepartmentFilter: null,
	userSpOrdersLocationFilter: null,
	userSpOrdersStatusFilter: null,
	userSpOrdersSorting: null
};

const getters = {
	getUserSpOrdersBrandsFilter: state => state.userSpOrdersBrandsFilter,
	getUserSpOrdersDepartmentFilter: state => state.userSpOrdersDepartmentFilter,
	getUserSpOrdersLocationFilter: state => state.userSpOrdersLocationFilter,
	getUserSpOrdersStatusFilter: state => state.userSpOrdersStatusFilter,
	getUserSpOrdersSorting: state => state.userSpOrdersSorting
};

const actions = {
	[SP_ORDER_CREATE]: ({ commit, dispatch }, data) => {
		let department = null;
		if (data.spOrder.department) {
			if (data.spOrder.department.label) department = data.spOrder.department.label;
			else department = data.spOrder.department
		}
		let location = null;
		if (data.spOrder.location) {
			if (data.spOrder.location.label) location = data.spOrder.location.label;
			else location = data.spOrder.location
		}
		let requestData = {
			id: data.spOrder.id,
			date: data.spOrder.date,
			sp_order_number: data.spOrder.spOrderNumber,
			department: department,
			vin: data.spOrder.vin,
			registration_number: data.spOrder.registrationNumber,
			brand_id: data.spOrder.brandId,
			model: data.spOrder.model,
			location: location,
			client_id: data.spOrder.clientId,
			brand_title: data.spOrder.brandTitle,
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_CREATE);
			SpOrdersService.create(requestData)
				.then(data => {
					commit(SP_ORDER_CREATE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(SP_ORDER_CREATE_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDERS_LIST_FILTERED]: ({ commit }, data) => {
		let requestData = {
			size: data.tableProps.itemsPerPage,
			page: data.tableProps.currentPage,
			sort: data.ctx.sortBy,
			desc: data.ctx.sortDesc,
		}
		if (data.tableProps.inputFilter.id) {
			requestData.idFilter = data.tableProps.inputFilter.id;
		}
		if (data.tableProps.inputFilter.date) {
			requestData.dateFilter = data.tableProps.inputFilter.date;
		}
		if (data.tableProps.inputFilter.updatedAt) {
			requestData.updatedAtFilter = data.tableProps.inputFilter.updatedAt;
		}
		if (data.tableProps.inputFilter.createdBy) {
			requestData.createdByFilter = data.tableProps.inputFilter.createdBy;
		}
		if (data.tableProps.inputFilter.department) {
			requestData.departmentFilter = data.tableProps.inputFilter.department;
		}
		if (data.tableProps.inputFilter.spOrderNumber) {
			requestData.spOrderNumberFilter = data.tableProps.inputFilter.spOrderNumber;
		}
		if (data.tableProps.inputFilter.clientId) {
			requestData.clientIdFilter = data.tableProps.inputFilter.clientId;
		}
		if (data.tableProps.inputFilter.registrationNumber) {
			requestData.registrationNumberFilter = data.tableProps.inputFilter.registrationNumber;
		}
		if (data.tableProps.inputFilter.vehicleId) {
			requestData.vehicleIdFilter = data.tableProps.inputFilter.vehicleId;
		}
		if (data.tableProps.inputFilter.brands) {
			requestData.brandsFilter = data.tableProps.inputFilter.brands;
		}
		if (data.tableProps.inputFilter.model) {
			requestData.modelFilter = data.tableProps.inputFilter.model;
		}
		if (data.tableProps.inputFilter.location) {
			requestData.locationFilter = data.tableProps.inputFilter.location;
		}
		if (data.tableProps.inputFilter.status) {
			requestData.statusFilter = data.tableProps.inputFilter.status;
		}
		if (data.tableProps.inputFilter.spOrderItemCatalogNumber) {
			requestData.spOrderItemCatalogNumberFilter = data.tableProps.inputFilter.spOrderItemCatalogNumber;
		}
		if (data.tableProps.inputFilter.showArchived) {
			requestData.showArchived = data.tableProps.inputFilter.showArchived;
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDERS_LIST_FILTERED);
			SpOrdersService.listFiltered(requestData)
				.then(responseData => {
					commit(SP_ORDERS_LIST_FILTERED_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(SP_ORDERS_LIST_FILTERED_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_DELETE]: ({ commit }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_DELETE);
			SpOrdersService.delete(requestData)
				.then(responseData => {
					commit(SP_ORDER_DELETE_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(SP_ORDER_DELETE_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_ARCHIVE]: ({ commit }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_ARCHIVE);
			SpOrdersService.archive(requestData)
				.then(responseData => {
					commit(SP_ORDER_ARCHIVE_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(SP_ORDER_ARCHIVE_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_UNARCHIVE]: ({ commit }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_UNARCHIVE);
			SpOrdersService.unarchive(requestData)
				.then(responseData => {
					commit(SP_ORDER_UNARCHIVE_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(SP_ORDER_UNARCHIVE_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_GET]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_GET);
			SpOrdersService.get(requestData)
				.then(data => {
					commit(SP_ORDER_GET_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(SP_ORDER_GET_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_SET_VIEWED]: ({ commit }, sp_order_id) => {
		let requestData = {
			sp_order_id: sp_order_id,
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_SET_VIEWED);
			SpOrdersService.setViewed(requestData)
				.then(responseData => {
					commit(SP_ORDER_SET_VIEWED_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(SP_ORDER_SET_VIEWED_ERROR, error);
					reject(error);
				});
		});
	},
	[USER_SP_ORDERS_BRANDS_FILTER]: ({ commit, dispatch }, data) => {
		commit(USER_SP_ORDERS_BRANDS_FILTER, data);
	},
	[USER_SP_ORDERS_DEPARTMENT_FILTER]: ({ commit, dispatch }, data) => {
		commit(USER_SP_ORDERS_DEPARTMENT_FILTER, data);
	},
	[USER_SP_ORDERS_LOCATION_FILTER]: ({ commit, dispatch }, data) => {
		commit(USER_SP_ORDERS_LOCATION_FILTER, data);
	},
	[USER_SP_ORDERS_STATUS_FILTER]: ({ commit, dispatch }, data) => {
		commit(USER_SP_ORDERS_STATUS_FILTER, data);
	},
	[USER_SP_ORDERS_SORTING]: ({ commit, dispatch }, data) => {
		commit(USER_SP_ORDERS_SORTING, data);
	}
};

const mutations = {
	[SP_ORDERS_LIST_FILTERED]: state => {
		state.status = 'loading';
	},
	[SP_ORDERS_LIST_FILTERED_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDERS_LIST_FILTERED_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_CREATE]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_CREATE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_CREATE_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_DELETE]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_DELETE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_DELETE_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_ARCHIVE]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_ARCHIVE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_ARCHIVE_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_UNARCHIVE]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_UNARCHIVE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_UNARCHIVE_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_GET]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_GET_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_GET_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_SET_VIEWED]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_SET_VIEWED_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_SET_VIEWED_ERROR]: state => {
		state.status = 'error';
	},
	[USER_SP_ORDERS_BRANDS_FILTER]: (state, responseData) => {
		state.status = 'success';
		Vue.set(state, 'userSpOrdersBrandsFilter', responseData);
	},
	[USER_SP_ORDERS_DEPARTMENT_FILTER]: (state, responseData) => {
		state.status = 'success';
		Vue.set(state, 'userSpOrdersDepartmentFilter', responseData);
	},
	[USER_SP_ORDERS_LOCATION_FILTER]: (state, responseData) => {
		state.status = 'success';
		Vue.set(state, 'userSpOrdersLocationFilter', responseData);
	},
	[USER_SP_ORDERS_STATUS_FILTER]: (state, responseData) => {
		state.status = 'success';
		Vue.set(state, 'userSpOrdersStatusFilter', responseData);
	},
	[USER_SP_ORDERS_SORTING]: (state, responseData) => {
		state.status = 'success';
		Vue.set(state, 'userSpOrdersSorting', responseData);
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};