const settings = {
	viewDFormat: 'dd. MM. yyyy',
	viewDtFormat: 'dd.MM.yyyy HH:mm',
	filterTimeoutLength: 1000,
	emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
	notificationMethodOptions: [
		{ value: 'email', label: 'Elektronski naslov' },
		{ value: 'halcom', label: 'Halcom' },
		{ value: 'none', label: 'Brez' },
	],
	orderTypeOptions: [
		{ value: 'single', label: 'Enkratna' },
		{ value: 'multiple', label: 'Terminska' },
	],
	ebillAreaOptions: [
		{
			label: 'CDP',
			value: 'CDP',
			active: false
		},
		{
			label: 'Toyota',
			value: 'Toyota',
			active: false
		},
		{
			label: 'Form.NET',
			value: 'Form.NET',
			active: false
		},
		{
			label: 'Vasco',
			value: 'Vasco',
			active: false
		}
	],
	ebillStatusOptions: [
		{
			label: 'V pripravi',
			value: 'inPreparation',
			active: false
		},
		{
			label: 'Pripravljen',
			value: 'prepared',
			active: false
		},
		{
			label: 'Poslan',
			value: 'sended',
			active: false
		}
	],
	spOrderDepartmentOptions: [
		{ value: 'kle', label: 'KLE.', active: false },
		{ value: 'meh', label: 'MEH.', active: false }
	],
	spOrderLocationOptions: [
		{ value: 'workshop', label: 'V delavnici', active: false },
		{ value: 'driving', label: 'Se vozi', active: false },
		{ value: 'partlyRepared-driving', label: 'Delno popravljeno - Se vozi', active: false },
		{ value: 'yard', label: 'Dvorišče', active: false },
	],
	spOrderItemsStatusOptions: [
		{
			label: 'Čakam dobavitelja',
			value: '100',
			variant: 'danger',
			active: false
		},
		{
			label: 'Naročeno',
			value: '200',
			variant: 'warning',
			active: false
		},
		{
			label: 'Na zalogi',
			value: '300',
			variant: 'success',
			active: false
		},
		{
			label: 'V pripravi',
			value: '#',
			variant: 'light',
			active: false
		},
	],
}
export default settings
