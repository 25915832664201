import {
	SP_ORDER_CLIENT_CREATE,
	SP_ORDER_CLIENT_CREATE_SUCCESS,
	SP_ORDER_CLIENT_CREATE_ERROR,
	SP_ORDER_CLIENTS_LIST_ALL,
	SP_ORDER_CLIENTS_LIST_ALL_SUCCESS,
	SP_ORDER_CLIENTS_LIST_ALL_ERROR,
} from "../actions/spOrderClients";
import Vue from "vue";
import SpOrderClientsService from '@/services/spOrderClients';

const state = {
	status: '',
	initialSpOrderClients: null,
	allSpOrderClients: null
};

const getters = {
	getInitialSpOrderClients: state => state.initialSpOrderClients,
	getAllSpOrderClients: state => state.allSpOrderClients
};

const actions = {
	[SP_ORDER_CLIENT_CREATE]: ({ commit, dispatch }, data) => {
		let requestData = {
			id: data.client.id,
			first_name: data.client.firstName,
			last_name: data.client.lastName,
			is_company: data.client.isCompany,
			company_title: data.client.companyTitle,
			company_contact_person: data.client.companyContactPerson,
			company_tax_number: data.client.companyTaxNumber,
			trr: data.client.trr,
			street: data.client.street,
			city_code: data.client.cityCode,
			city: data.client.city,
			phone_number: data.client.phoneNumber,
			email: data.client.email,
			note: data.client.note,
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_CLIENT_CREATE);
			SpOrderClientsService.create(requestData)
				.then(data => {
					commit(SP_ORDER_CLIENT_CREATE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(SP_ORDER_CLIENT_CREATE_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_CLIENTS_LIST_ALL]: ({ commit, dispatch }, data) => {
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_CLIENTS_LIST_ALL);
			SpOrderClientsService.listAll()
				.then(data => {
					commit(SP_ORDER_CLIENTS_LIST_ALL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(SP_ORDER_CLIENTS_LIST_ALL_ERROR, error);
					reject(error);
				});
		});
	},
};

const mutations = {
	[SP_ORDER_CLIENT_CREATE]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_CLIENT_CREATE_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[SP_ORDER_CLIENT_CREATE_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_CLIENTS_LIST_ALL]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_CLIENTS_LIST_ALL_SUCCESS]: (state, responseData) => {
		state.status = 'success';
		Vue.set(state, 'allSpOrderClients', responseData.clients);
	},
	[SP_ORDER_CLIENTS_LIST_ALL_ERROR]: state => {
		state.status = 'error';
		Vue.set(state, 'allSpOrderClients', null);
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};