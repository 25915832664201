import {
	EBILL_CREATE,
	EBILL_CREATE_SUCCESS,
	EBILL_CREATE_ERROR,
	EBILLS_LIST_FILTERED,
	EBILLS_LIST_FILTERED_SUCCESS,
	EBILLS_LIST_FILTERED_ERROR,
	EBILLS_DOWNLOAD_FILE,
	EBILLS_DOWNLOAD_FILE_SUCCESS,
	EBILLS_DOWNLOAD_FILE_ERROR,
	EBILL_ARCHIVE,
	EBILL_ARCHIVE_SUCCESS,
	EBILL_ARCHIVE_ERROR,
	ENVELOPE_GET,
	ENVELOPE_GET_SUCCESS,
	ENVELOPE_GET_ERROR,
	EBILL_PREPARE,
	EBILL_PREPARE_SUCCESS,
	EBILL_PREPARE_ERROR,
	EBILL_REJECT,
	EBILL_REJECT_SUCCESS,
	EBILL_REJECT_ERROR,
	EBILL_SEND_HALCOM,
	EBILL_SEND_HALCOM_SUCCESS,
	EBILL_SEND_HALCOM_ERROR,
	CHANGE_CLIENT,
	CHANGE_CLIENT_SUCCESS,
	CHANGE_CLIENT_ERROR,
	CHANGE_ORDER,
	CHANGE_ORDER_SUCCESS,
	CHANGE_ORDER_ERROR,
	EBILL_UNARCHIVE,
	EBILL_UNARCHIVE_SUCCESS,
	EBILL_UNARCHIVE_ERROR,
	EBILL_DELETE,
	EBILL_DELETE_SUCCESS,
	EBILL_DELETE_ERROR,
	EBILL_RESET,
	EBILL_RESET_SUCCESS,
	EBILL_RESET_ERROR,
} from "../actions/ebills";
import Vue from "vue";
import EbillsService from '@/services/ebills';

const state = {
	status: ''
};

const getters = {
};

const actions = {
	[EBILL_CREATE]: ({ commit, dispatch }, data) => {
		let requestData = {
			id: data.ebill.id,
			client_id: data.ebill.client_id,
			area: data.ebill.area,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_CREATE);
			EbillsService.create(requestData)
				.then(data => {
					commit(EBILL_CREATE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_CREATE_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILLS_LIST_FILTERED]: ({ commit }, data) => {
		let requestData = {
			size: data.tableProps.itemsPerPage,
			page: data.tableProps.currentPage,
			sort: data.ctx.sortBy,
			desc: data.ctx.sortDesc,
		}
		if (data.tableProps.inputFilter.clientTitle) {
			requestData.clientTitleFilter = data.tableProps.inputFilter.clientTitle;
		}
		if (data.tableProps.inputFilter.ebillNumber) {
			requestData.ebillNumberFilter = data.tableProps.inputFilter.ebillNumber;
		}
		if (data.tableProps.inputFilter.envelopeDocId) {
			requestData.envelopeDocIdFilter = data.tableProps.inputFilter.envelopeDocId;
		}
		if (data.tableProps.inputFilter.ebillAmountToBePaid) {
			requestData.ebillAmountToBePaidFilter = data.tableProps.inputFilter.ebillAmountToBePaid;
		}
		if (data.tableProps.inputFilter.envelopePurpose) {
			requestData.envelopePurposeFilter = data.tableProps.inputFilter.envelopePurpose;
		}
		if (data.tableProps.inputFilter.area) {
			requestData.areaFilter = data.tableProps.inputFilter.area;
		}
		if (data.tableProps.inputFilter.status) {
			requestData.statusFilter = data.tableProps.inputFilter.status[0];
		}
		if (data.tableProps.inputFilter.showArchived) {
			requestData.showArchived = data.tableProps.inputFilter.showArchived;
		}
		if (data.tableProps.inputFilter.clientId) {
			requestData.clientIdFilter = data.tableProps.inputFilter.clientId;
		}
		return new Promise((resolve, reject) => {
			commit(EBILLS_LIST_FILTERED);
			EbillsService.listFiltered(requestData)
				.then(responseData => {
					commit(EBILLS_LIST_FILTERED_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(EBILLS_LIST_FILTERED_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILLS_DOWNLOAD_FILE]: ({ commit }, data) => {
		let requestData = {
			filepath: data.filepath,
			ebillToPdf: data.ebillToPdf
		}
		return new Promise((resolve, reject) => {
			commit(EBILLS_DOWNLOAD_FILE);
			EbillsService.downloadFile(requestData)
				.then(responseData => {
					commit(EBILLS_DOWNLOAD_FILE_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(EBILLS_DOWNLOAD_FILE_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILL_ARCHIVE]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_ARCHIVE);
			EbillsService.archive(requestData)
				.then(data => {
					commit(EBILL_ARCHIVE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_ARCHIVE_ERROR, error);
					reject(error);
				});
		});
	},
	[ENVELOPE_GET]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(ENVELOPE_GET);
			EbillsService.getEnvelope(requestData)
				.then(data => {
					commit(ENVELOPE_GET_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(ENVELOPE_GET_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILL_PREPARE]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_PREPARE);
			EbillsService.prepare(requestData)
				.then(data => {
					commit(EBILL_PREPARE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_PREPARE_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILL_REJECT]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_REJECT);
			EbillsService.reject(requestData)
				.then(data => {
					commit(EBILL_REJECT_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_REJECT_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILL_SEND_HALCOM]: ({ commit, dispatch }, data) => {
		let requestData = {
			ebillId: data.ebillId,
			attachments: data.attachments,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_SEND_HALCOM);
			EbillsService.sendHalcom(requestData)
				.then(data => {
					commit(EBILL_SEND_HALCOM_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_SEND_HALCOM_ERROR, error);
					reject(error);
				});
		});
	},
	[CHANGE_CLIENT]: ({ commit, dispatch }, data) => {
		let requestData = {
			ebillId: data.ebillId,
			clientId: data.clientId,
		}
		return new Promise((resolve, reject) => {
			commit(CHANGE_CLIENT);
			EbillsService.changeClient(requestData)
				.then(data => {
					commit(CHANGE_CLIENT_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(CHANGE_CLIENT_ERROR, error);
					reject(error);
				});
		});
	},
	[CHANGE_ORDER]: ({ commit, dispatch }, data) => {
		let requestData = {
			ebillId: data.ebillId,
			orderId: data.orderId,
		}
		return new Promise((resolve, reject) => {
			commit(CHANGE_ORDER);
			EbillsService.changeOrder(requestData)
				.then(data => {
					commit(CHANGE_ORDER_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(CHANGE_ORDER_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILL_UNARCHIVE]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_UNARCHIVE);
			EbillsService.unarchive(requestData)
				.then(data => {
					commit(EBILL_UNARCHIVE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_UNARCHIVE_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILL_DELETE]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_DELETE);
			EbillsService.delete(requestData)
				.then(data => {
					commit(EBILL_DELETE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_DELETE_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILL_RESET]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_RESET);
			EbillsService.reset(requestData)
				.then(data => {
					commit(EBILL_RESET_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_RESET_ERROR, error);
					reject(error);
				});
		});
	},
};

const mutations = {
	[EBILL_CREATE]: state => {
		state.status = 'loading';
	},
	[EBILL_CREATE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILL_CREATE_ERROR]: state => {
		state.status = 'error';
	},
	[EBILLS_LIST_FILTERED]: state => {
		state.status = 'loading';
	},
	[EBILLS_LIST_FILTERED_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILLS_LIST_FILTERED_ERROR]: state => {
		state.status = 'error';
	},
	[EBILLS_DOWNLOAD_FILE]: state => {
		state.status = 'loading';
	},
	[EBILLS_DOWNLOAD_FILE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILLS_DOWNLOAD_FILE_ERROR]: state => {
		state.status = 'error';
	},
	[EBILL_ARCHIVE]: state => {
		state.status = 'loading';
	},
	[EBILL_ARCHIVE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILL_ARCHIVE_ERROR]: state => {
		state.status = 'error';
	},
	[ENVELOPE_GET]: state => {
		state.status = 'loading';
	},
	[ENVELOPE_GET_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[ENVELOPE_GET_ERROR]: state => {
		state.status = 'error';
	},
	[EBILL_PREPARE]: state => {
		state.status = 'loading';
	},
	[EBILL_PREPARE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILL_PREPARE_ERROR]: state => {
		state.status = 'error';
	},
	[EBILL_REJECT]: state => {
		state.status = 'loading';
	},
	[EBILL_REJECT_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILL_REJECT_ERROR]: state => {
		state.status = 'error';
	},
	[EBILL_SEND_HALCOM]: state => {
		state.status = 'loading';
	},
	[EBILL_SEND_HALCOM_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILL_SEND_HALCOM_ERROR]: state => {
		state.status = 'error';
	},
	[CHANGE_CLIENT]: state => {
		state.status = 'loading';
	},
	[CHANGE_CLIENT_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[CHANGE_CLIENT_ERROR]: state => {
		state.status = 'error';
	},
	[CHANGE_ORDER]: state => {
		state.status = 'loading';
	},
	[CHANGE_ORDER_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[CHANGE_ORDER_ERROR]: state => {
		state.status = 'error';
	},
	[EBILL_UNARCHIVE]: state => {
		state.status = 'loading';
	},
	[EBILL_UNARCHIVE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILL_UNARCHIVE_ERROR]: state => {
		state.status = 'error';
	},
	[EBILL_DELETE]: state => {
		state.status = 'loading';
	},
	[EBILL_DELETE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILL_DELETE_ERROR]: state => {
		state.status = 'error';
	},
	[EBILL_RESET]: state => {
		state.status = 'loading';
	},
	[EBILL_RESET_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EBILL_RESET_ERROR]: state => {
		state.status = 'error';
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};