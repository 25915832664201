export default function viewTime (value) {
	try {
		let result = 'hh:mm:ss';
		if (value) {
			let datum = new Date(value);
			result = datum.toLocaleTimeString('sl-SL');
		}
		return result;

	} catch (ex) {
		console.error(ex.toString());
		return 'hh:mm:ss';
	}
}
