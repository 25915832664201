import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'vehicles/';

class vehiclesService {
	listFiltered(requestData) {
		const url = API_PATH + 'list_filtered';
		return new Promise((resolve, reject) => {
			axios.post(url, requestData)
				.then(response => {
					// handle success
					Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
				})
				.catch(error => {
					// handle error
					Handlers.ERROR_HANDLER(url, error, null, reject);
				});
		});
	}
}

export default new vehiclesService();