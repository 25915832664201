import {
	EDITING_RECORD_START,
	EDITING_RECORD_START_SUCCESS,
	EDITING_RECORD_START_ERROR,
	EDITING_RECORD_END,
	EDITING_RECORD_END_SUCCESS,
	EDITING_RECORD_END_ERROR
} from "../actions/editingRecords";
import Vue from "vue";
import EditingRecordsService from '@/services/editingRecords';

const state = {
	status: ''
};

const getters = {
};

const actions = {
	[EDITING_RECORD_START]: ({ commit, dispatch }, data) => {
		let requestData = {
			type_id: data.editingRecord.type.id,
			object_id: data.editingRecord.object_id,
			data_at_start: data.editingRecord.data_at_start
		}
		return new Promise((resolve, reject) => {
			commit(EDITING_RECORD_START);
			EditingRecordsService.start(requestData)
				.then(data => {
					commit(EDITING_RECORD_START_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EDITING_RECORD_START_ERROR, error);
					reject(error);
				});
		});
	},
	[EDITING_RECORD_END]: ({ commit, dispatch }, data) => {
		let requestData = {
			id: data.editingRecord.id
		}
		return new Promise((resolve, reject) => {
			commit(EDITING_RECORD_END);
			EditingRecordsService.end(requestData)
				.then(data => {
					commit(EDITING_RECORD_END_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EDITING_RECORD_END_ERROR, error);
					reject(error);
				});
		});
	}
};

const mutations = {
	[EDITING_RECORD_START]: state => {
		state.status = 'loading';
	},
	[EDITING_RECORD_START_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EDITING_RECORD_START_ERROR]: state => {
		state.status = 'error';
	},
	[EDITING_RECORD_END]: state => {
		state.status = 'loading';
	},
	[EDITING_RECORD_END_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EDITING_RECORD_END_ERROR]: state => {
		state.status = 'error';
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};