import 'core-js/stable'
import axios from 'axios'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import LaravelPermissions from './tools/permissions';
import MessageBox from '@/views/components/MessageBox'
import Handlers from '@/services/handlers'
import filters from '@/filters';
import Toastr from 'vue-toastr';
import BootstrapVue from 'bootstrap-vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import vCalendar from 'v-calendar';
import ClientCreateEditModal from '@/views/modals/ClientCreateEdit';
import SpOrderClientCreateEditModal from '@/views/modals/SpOrderClientsCreateEdit';
import { USER_DETAILS } from './store/actions/users'
import VueCurrencyInput from 'vue-currency-input';
import vNumeralFilter from 'vue-numeral-filter';


Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);
Vue.component('app-mbox', MessageBox);
require('vue-toastr/src/vue-toastr.scss');
Vue.component('vue-toastr', Toastr);
Vue.component('vue-loading', Loading);
Vue.use(LaravelPermissions);
Vue.component('v-select', vSelect)
Vue.component("client-create-edit-modal", ClientCreateEditModal)
Vue.component("sp-order-client-create-edit-modal", SpOrderClientCreateEditModal)

Vue.use(vCalendar, {
	locales: {
		'sl-SI': {
			firstDayOfWeek: 2,
			masks: {
				L: 'DD. MM. YYYY',
				weekdays: 'WW'
			}
		}
	}
});

Vue.use(VueCurrencyInput, {
	globalOptions: {
		currency: 'EUR',
		locale: 'sl'
	}
});

Vue.use(vNumeralFilter, {
	locale: 'sl'
});

axios.defaults.baseURL = process.env.VUE_APP_API;
const user_token = localStorage.getItem('user-token');
if (user_token) {
	// get access token
	axios.defaults.headers.common['Authorization'] = user_token;
	store.dispatch(USER_DETAILS)
	.then(() => {
		// get user permissions after getting details
		let user = store.getters.getProfile;
		window.Laravel = {
			roles: user.roles,
			permissions: user.permissions
		};
	})
	.catch(error => {
		console.log(error);
	});
}

Vue.use(filters);
Vue.use(BootstrapVue);

Vue.mixin({
	methods: {
		mixinGetInitials: function (name) {
			if (name != null) {
				let nameSplited = name.split(' ');
				if (nameSplited.length > 1) {
					if (nameSplited[0].length > 0 && nameSplited[1].length > 0) {
						return (nameSplited[0][0] + nameSplited[1][0]).toUpperCase();
					} else {
						return '--';
					}
				} else if (name.length > 1) {
					return (name[0] + name[1]).toUpperCase();
				} else {
					return '--';
				}
			}
			return '--';
		},
		mixinGetClientFullName: function (client) {
			if (client.is_company == 1) {
				return (client.company_title != null ? client.company_title : '-') + ' (' + (client.company_contact_person != null ? client.company_contact_person : '-') + ')';
			}
			return (client.first_name != null ? client.first_name : '-') + ' ' + (client.last_name != null ? client.last_name : '-');
		},
		convertBytes: function(bytes) {
			const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
		  
			if (bytes == 0) {
			  return "n/a"
			}
		  
			const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
		  
			if (i == 0) {
			  return bytes + " " + sizes[i]
			}
		  
			return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i]
		  }
	},
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  },
  created() {
    const mr = this.$router;
    const ms = this.$store;
    axios.interceptors.response.use(undefined, function (error) {
      return Handlers.UNAUTHORIZED_ERROR_HANDLER(error, ms, mr);
    });
  }
})
