import {
	VEHICLE_BRANDS_LIST_ALL,
	VEHICLE_BRANDS_LIST_ALL_SUCCESS,
	VEHICLE_BRANDS_LIST_ALL_ERROR
} from "../actions/vehicleBrands";
import Vue from "vue";
import VehicleBrands from '@/services/vehicleBrands';

const state = {
	status: '',
	allVehicleBrands: null
};

const getters = {
	getAllVehicleBrands: state => state.allVehicleBrands
};

const actions = {
	[VEHICLE_BRANDS_LIST_ALL]: ({ commit, dispatch }, data) => {
		return new Promise((resolve, reject) => {
			commit(VEHICLE_BRANDS_LIST_ALL);
			VehicleBrands.listAll()
				.then(data => {
					commit(VEHICLE_BRANDS_LIST_ALL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(VEHICLE_BRANDS_LIST_ALL_ERROR, error);
					reject(error);
				});
		});
	},
};

const mutations = {
	[VEHICLE_BRANDS_LIST_ALL]: state => {
		state.status = 'loading';
	},
	[VEHICLE_BRANDS_LIST_ALL_SUCCESS]: (state, responseData) => {
		state.status = 'success';
		Vue.set(state, 'allVehicleBrands', responseData.vehicleBrands);
	},
	[VEHICLE_BRANDS_LIST_ALL_ERROR]: state => {
		state.status = 'error';
		Vue.set(state, 'allVehicleBrands', null);
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};