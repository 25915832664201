import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'sp_order_clients/';

class SpOrderClientsService {
    create(requestData) {
        const url = API_PATH + 'create';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
    listAll(requestData) {
        const url = API_PATH + 'all';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
}

export default new SpOrderClientsService();