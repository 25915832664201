import {
	SP_ORDER_ITEM_CREATE,
	SP_ORDER_ITEM_CREATE_SUCCESS,
	SP_ORDER_ITEM_CREATE_ERROR,
	SP_ORDER_ITEMS_LIST_FILTERED,
	SP_ORDER_ITEMS_LIST_FILTERED_SUCCESS,
	SP_ORDER_ITEMS_LIST_FILTERED_ERROR,
	SP_ORDER_ITEM_DELETE,
	SP_ORDER_ITEM_DELETE_SUCCESS,
	SP_ORDER_ITEM_DELETE_ERROR,
	SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT,
	SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT_SUCCESS,
	SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT_ERROR
} from "../actions/spOrderItems";
import Vue from "vue";
import SpOrderItemsService from '@/services/spOrderItems';

const state = {
	status: ''
};

const getters = {
};

const actions = {
	[SP_ORDER_ITEM_CREATE]: ({ commit, dispatch }, data) => {
		if (data.spOrderItem.status) {
			data.spOrderItem.status = data.spOrderItem.status.value;
		}
		let requestData = data.spOrderItem;
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_ITEM_CREATE);
			SpOrderItemsService.create(requestData)
				.then(data => {
					commit(SP_ORDER_ITEM_CREATE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(SP_ORDER_ITEM_CREATE_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_ITEMS_LIST_FILTERED]: ({ commit }, data) => {
		let requestData = {
			size: data.tableProps.itemsPerPage,
			page: data.tableProps.currentPage,
			sort: data.ctx.sortBy,
			desc: data.ctx.sortDesc,
		}
		if (data.tableProps.inputFilter.spOrderId) {
			requestData.spOrderIdFilter = data.tableProps.inputFilter.spOrderId;
		}
		if (data.tableProps.debtOnly) {
			requestData.debtOnly = data.tableProps.debtOnly;
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_ITEMS_LIST_FILTERED);
			SpOrderItemsService.listFiltered(requestData)
				.then(responseData => {
					commit(SP_ORDER_ITEMS_LIST_FILTERED_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(SP_ORDER_ITEMS_LIST_FILTERED_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_ITEM_DELETE]: ({ commit }, id) => {
		let requestData = {
			id: id
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_ITEM_DELETE);
			SpOrderItemsService.delete(requestData)
				.then(responseData => {
					commit(SP_ORDER_ITEM_DELETE_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(SP_ORDER_ITEM_DELETE_ERROR, error);
					reject(error);
				});
		});
	},
	[SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT]: ({ commit }, data) => {
		let requestData = {
			type: data.type
		}
		return new Promise((resolve, reject) => {
			commit(SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT);
			SpOrderItemsService.download_debt(requestData)
				.then(responseData => {
					commit(SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT_ERROR, error);
					reject(error);
				});
		});
	},
};

const mutations = {
	[SP_ORDER_ITEM_CREATE]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_ITEM_CREATE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_ITEM_CREATE_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_ITEMS_LIST_FILTERED]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_ITEMS_LIST_FILTERED_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_ITEMS_LIST_FILTERED_ERROR]: state => {
		state.status = 'error';
	},
	[SP_ORDER_ITEM_DELETE]: state => {
		state.status = 'loading';
	},
	[SP_ORDER_ITEM_DELETE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[SP_ORDER_ITEM_DELETE_ERROR]: state => {
		state.status = 'error';
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};