export const SP_ORDERS_LIST_FILTERED = "SP_ORDERS_LIST_FILTERED";
export const SP_ORDERS_LIST_FILTERED_SUCCESS = "SP_ORDERS_LIST_FILTERED_SUCCESS";
export const SP_ORDERS_LIST_FILTERED_ERROR = "SP_ORDERS_LIST_FILTERED_ERROR";
export const SP_ORDER_CREATE = "SP_ORDER_CREATE";
export const SP_ORDER_CREATE_SUCCESS = "SP_ORDER_CREATE_SUCCESS";
export const SP_ORDER_CREATE_ERROR = "SP_ORDER_CREATE_ERROR";
export const SP_ORDER_DELETE = "SP_ORDER_DELETE";
export const SP_ORDER_DELETE_SUCCESS = "SP_ORDER_DELETE_SUCCESS";
export const SP_ORDER_DELETE_ERROR = "SP_ORDER_DELETE_ERROR";
export const SP_ORDER_ARCHIVE = "SP_ORDER_ARCHIVE";
export const SP_ORDER_ARCHIVE_SUCCESS = "SP_ORDER_ARCHIVE_SUCCESS";
export const SP_ORDER_ARCHIVE_ERROR = "SP_ORDER_ARCHIVE_ERROR";
export const SP_ORDER_UNARCHIVE = "SP_ORDER_UNARCHIVE";
export const SP_ORDER_UNARCHIVE_SUCCESS = "SP_ORDER_UNARCHIVE_SUCCESS";
export const SP_ORDER_UNARCHIVE_ERROR = "SP_ORDER_UNARCHIVE_ERROR";
export const SP_ORDER_GET = "SP_ORDER_GET";
export const SP_ORDER_GET_SUCCESS = "SP_ORDER_GET_SUCCESS";
export const SP_ORDER_GET_ERROR = "SP_ORDER_GET_ERROR";
export const SP_ORDER_SET_VIEWED = "SP_ORDER_SET_VIEWED";
export const SP_ORDER_SET_VIEWED_SUCCESS = "SP_ORDER_SET_VIEWED_SUCCESS";
export const SP_ORDER_SET_VIEWED_ERROR = "SP_ORDER_SET_VIEWED_ERROR";
export const USER_SP_ORDERS_BRANDS_FILTER = "USER_SP_ORDERS_BRANDS_FILTER";
export const USER_SP_ORDERS_DEPARTMENT_FILTER = "USER_SP_ORDERS_DEPARTMENT_FILTER";
export const USER_SP_ORDERS_LOCATION_FILTER = "USER_SP_ORDERS_LOCATION_FILTER";
export const USER_SP_ORDERS_STATUS_FILTER = "USER_SP_ORDERS_STATUS_FILTER";
export const USER_SP_ORDERS_SORTING = "USER_SP_ORDERS_SORTING";