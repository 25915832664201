import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'sp_orders/';

class spOrdersService {
    create(requestData) {
        const url = API_PATH + 'create';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	listFiltered(requestData) {
        const url = API_PATH + 'list_filtered';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
    delete(spOrder) {
        const url = API_PATH + 'delete';
        return new Promise((resolve, reject) => {
            axios.post(url, spOrder.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
    archive(spOrder) {
        const url = API_PATH + 'archive';
        return new Promise((resolve, reject) => {
            axios.post(url, spOrder.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
    unarchive(spOrder) {
        const url = API_PATH + 'unarchive';
        return new Promise((resolve, reject) => {
            axios.post(url, spOrder.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	get(requestData) {
        const url = API_PATH + 'get';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	setViewed(spOrder) {
        const url = API_PATH + 'set_viewed';
        return new Promise((resolve, reject) => {
            axios.post(url, spOrder.sp_order_id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
}

export default new spOrdersService();