import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'sp_order_items/';

class spOrderItemsService {
    create(requestData) {
        const url = API_PATH + 'create';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	listFiltered(requestData) {
        const url = API_PATH + 'list_filtered';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	delete(spOrderItem) {
        const url = API_PATH + 'delete';
        return new Promise((resolve, reject) => {
            axios.post(url, spOrderItem.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	download_debt(requestData) {
        const url = API_PATH + 'download_debt';
		let headers = { 'responseType': 'arraybuffer' }
        return new Promise((resolve, reject) => {
            axios.post(url, requestData, headers)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
}

export default new spOrderItemsService();