import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'ebills/';

class EbillsService {
    create(requestData) {
        const url = API_PATH + 'create';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	listFiltered(requestData) {
        const url = API_PATH + 'list_filtered';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	downloadFile(requestData) {
        const url = API_PATH + 'download_file';
		let headers = { 'responseType': 'arraybuffer' }
        return new Promise((resolve, reject) => {
            axios.post(url, requestData, headers)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	archive(requestData) {
        const url = API_PATH + 'archive';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	getEnvelope(requestData) {
        const url = API_PATH + 'getEnvelope';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	prepare(requestData) {
        const url = API_PATH + 'prepare';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	reject(requestData) {
        const url = API_PATH + 'reject';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	sendHalcom(requestData) {
        const url = API_PATH + 'send_halcom';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	changeClient(requestData) {
        const url = API_PATH + 'change_client';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	changeOrder(requestData) {
        const url = API_PATH + 'change_order';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	unarchive(requestData) {
        const url = API_PATH + 'unarchive';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	delete(requestData) {
        const url = API_PATH + 'delete';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	reset(requestData) {
        const url = API_PATH + 'reset';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
}

export default new EbillsService();