import {
	VEHICLES_LIST_FILTERED,
	VEHICLES_LIST_FILTERED_SUCCESS,
	VEHICLES_LIST_FILTERED_ERROR,
} from "../actions/vehicles";
import Vue from "vue";
import VehiclesService from '@/services/vehicles';

const state = {
	status: ''
};

const getters = {
};

const actions = {
	[VEHICLES_LIST_FILTERED]: ({ commit }, data) => {
		let requestData = {
			vinFilter: data
		}
		return new Promise((resolve, reject) => {
			commit(VEHICLES_LIST_FILTERED);
			VehiclesService.listFiltered(requestData)
				.then(responseData => {
					commit(VEHICLES_LIST_FILTERED_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(VEHICLES_LIST_FILTERED_ERROR, error);
					reject(error);
				});
		});
	}
};

const mutations = {
	[VEHICLES_LIST_FILTERED]: state => {
		state.status = 'loading';
	},
	[VEHICLES_LIST_FILTERED_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[VEHICLES_LIST_FILTERED_ERROR]: state => {
		state.status = 'error';
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};