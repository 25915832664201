export const SP_ORDER_ITEM_CREATE = "SP_ORDER_ITEM_CREATE";
export const SP_ORDER_ITEM_CREATE_SUCCESS = "SP_ORDER_ITEM_CREATE_SUCCESS";
export const SP_ORDER_ITEM_CREATE_ERROR = "SP_ORDER_ITEM_CREATE_ERROR";
export const SP_ORDER_ITEMS_LIST_FILTERED = "SP_ORDER_ITEMS_LIST_FILTERED";
export const SP_ORDER_ITEMS_LIST_FILTERED_SUCCESS = "SP_ORDER_ITEMS_LIST_FILTERED_SUCCESS";
export const SP_ORDER_ITEMS_LIST_FILTERED_ERROR = "SP_ORDER_ITEMS_LIST_FILTERED_ERROR";
export const SP_ORDER_ITEM_DELETE = "SP_ORDER_ITEM_DELETE";
export const SP_ORDER_ITEM_DELETE_SUCCESS = "SP_ORDER_ITEM_DELETE_SUCCESS";
export const SP_ORDER_ITEM_DELETE_ERROR = "SP_ORDER_ITEM_DELETE_ERROR";
export const SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT = "SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT";
export const SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT_SUCCESS = "SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT_SUCCESS";
export const SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT_ERROR = "SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT_ERROR";