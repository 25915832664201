<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" :title="title" :size="modalSize" class="modal-primary" @hidden="clearModalData">
		<CRow>
			<CCol col=4 class="mb-2">
				<span class="costum-switch prepend">
					Podjetje
				</span>
				<span class="costum-switch content">
					<CSwitch
						class="pt-2"
						:disabled="isLoading"
						variant="opposite"
						color="primary"
						v-bind="isCompanyLabelTxt"
						:checked.sync="client.isCompany">
					</CSwitch>
				</span>
			</CCol>
		</CRow>
		<div v-if="client.isCompany">
			<CRow>
				<CCol>
					<CInput
						label="Naziv"
						v-model="client.companyTitle"/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CInput
						label="Kontaktna oseba"
						v-model="client.companyContactPerson"/>
				</CCol>
				<CCol>
					<CInput
						label="Davčna številka"
						v-model="client.companyTaxNumber">
						<template #append>
							<CButton color="primary" size="sm" title="Poišči po davčni številki" @click.stop="searchByTaxNumber()">
								<CIcon name="cil-magnifying-glass"/>
							</CButton>
						</template>
					</CInput>
				</CCol>
			</CRow>
			<CRow>
			</CRow>
		</div>
		<div v-else>
			<CRow>
				<CCol>
					<CInput
						label="Ime"
						v-model="client.firstName"/>
				</CCol>
				<CCol>
					<CInput
						label="Priimek"
						v-model="client.lastName"/>
				</CCol>
			</CRow>
		</div>
		<CRow>
			<CCol>
				<CInput
					label="Transakcijski račun"
					v-model="client.trr"/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<CInput
					label="Ulica in hišna št."
					v-model="client.street"/>
			</CCol>
		</CRow>
		<CRow>
			<CCol col=4>
				<CInput
					label="Poštna št."
					v-model="client.cityCode"/>
			</CCol>
			<CCol>
				<CInput
					label="Kraj"
					v-model="client.city"/>
			</CCol>
		</CRow>
		<CRow>
			<CCol col=4>
				<CInput
					label="Telefonska številka"
					v-model="client.phoneNumber"/>
			</CCol>
			<CCol>
				<CInput
					label="Email"
					:isValid="isValidEmail"
					:invalid-feedback="emailInvalidFeedback"
					v-model="client.email"/>
			</CCol>
		</CRow>
		<CRow>
		</CRow>
		<CRow>
			<CCol>
				<CTextarea
					label="Opomba"
					rows="3"
					v-model="client.note"/>
			</CCol>
		</CRow>
		<CAlert v-show="message.text != null" :color="message.type">
			{{ message.text }}
		</CAlert>
		<template slot="modal-footer">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="onCancelModal()">Prekliči</b-button>
						<b-button :disabled="okButton.disabled" variant="primary" @click.stop="onOkModal()">{{ okButton.label }}</b-button>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
	</b-modal>
</template>

<script>
import settings from '@/settings.js';
import { CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS } from "@/store/actions/clients";
import { SP_ORDER_CLIENT_CREATE, SP_ORDER_CLIENTS_LIST_ALL } from "@/store/actions/spOrderClients";
import PermissionCheck from '@/tools/permission-check';

export default {
	name: 'sp-order-clients-create-edit-modal',
	components: {
	},
	props: {
	},
	methods: {
		showModal: function (modalInput) {
			/*
				mode 1 => Create
				mode 2 => Edit
			*/
			this.mode = modalInput.mode;
			this.initModal(modalInput.client);
		},
		initModal: function (client) {
			if (this.mode === this.mode_CREATE1) {
				this.title = 'Dodajanje stranke (ND)';
				this.okButton.label = 'Dodaj';
				if (client) {
					this.initClient(client);
				}
			} else if (this.mode === this.mode_EDIT2) {
				this.title = 'Urejanje stranke ' + this.mixinGetClientFullName(client) + ' (ND)';
				this.initClient(client);
				this.okButton.label = 'Shrani';
			} else {
				this.mode = this.mode_UNKNOWN0;
				this.title = 'Neznan način';
				this.okButton.label = 'Zapri';
			}
			this.$refs.modal.show();
		},
		initClient: function (client) {
			this.client.id = client.id;
			this.client.firstName = client.first_name;
			this.client.lastName = client.last_name;
			this.client.isCompany = client.is_company == 1 ? true : false;
			this.client.companyTitle = client.company_title;
			this.client.companyContactPerson = client.company_contact_person;
			this.client.companyTaxNumber = client.company_tax_number;
			this.client.trr = client.trr;
			this.client.street = client.street;
			this.client.cityCode = client.city_code;
			this.client.city = client.city;
			this.client.phoneNumber = client.phone_number;
			this.client.email = client.email;
			this.client.note = client.note;
		},
		onOkModal: function () {
			this.startProgress();
			this.$store.dispatch(SP_ORDER_CLIENT_CREATE, { client: this.client })
				.then((responseDataCreate) => {
					this.$store.dispatch(SP_ORDER_CLIENTS_LIST_ALL)
						.then((responseData) => {
							this.stopProgress();
							this.$refs.modal.hide();
							setTimeout(() => {
								this.$emit('emit_spOrderClientCreatedOrEdited', responseDataCreate);
							}, 200);
							return responseData.clients;
						})
						.catch(error => {
							console.error(error);
						});
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						if (this.mode === this.mode_CREATE1) {
							this.showAlert('Napaka pri dodajanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else if (this.mode === this.mode_EDIT2) {
							this.showAlert('Napaka pri urejanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Neznan način modala! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						}
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		onCancelModal: function () {
			this.$emit('emit_spOrderClientModalCancel');
			this.$refs.modal.hide();
		},
		clearModalData: function () {
			this.isLoading = false;
			this.title = '';
			this.okButton = {
				label: 'Shrani',
				progress: false,
				disabled: false
			};
			this.showAlert();
			this.mode = 0;
			this.resetClientObject();
		},
		resetClientObject: function () {
			this.client.id = null;
			this.client.firstName = '';
			this.client.lastName = '';
			this.client.isCompany = false;
			this.client.companyTitle = '';
			this.client.companyContactPerson = '';
			this.client.companyTaxNumber = '';
			this.client.trr = '';
			this.client.street = '';
			this.client.cityCode = '';
			this.client.city = '';
			this.client.phoneNumber = '';
			this.client.email = null;
			this.client.note = '';
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		searchByTaxNumber: function () {
			this.startProgress();
			this.$store.dispatch(CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS, { taxNumber: this.client.companyTaxNumber })
				.then((responseData) => {
					this.stopProgress();
					if (responseData.message === 'found') {
						this.showAlert();
						this.client.companyTitle = responseData.title;
						this.client.street = responseData.street;
						this.client.cityCode = responseData.cityCode;
						this.client.city = responseData.city;
					} else if (responseData.message === 'multipleFound') {
						this.showAlert('Najdenih je več rezultatov, vnesite celotno davčno', 'warning');
					} else {
						this.showAlert('Ni najdenih rezultatov s to davčno številko.', 'warning');
					}
					console.log(responseData);
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.showAlert('Napaka pri iskanju z API-jem! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		}
	},
	data: function () {
		return {
			settings,
			isLoading: false,
			title: '',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			mode_UNKNOWN0: 0,
			mode_CREATE1: 1,
			mode_EDIT2: 2,
			mode: 0,
			client: {
				id: null,
				firstName: '',
				lastName: '',
				isCompany: false,
				companyTitle: '',
				companyContactPerson: '',
				companyTaxNumber: '',
				trr: '',
				street: '',
				cityCode: '',
				city: '',
				phoneNumber: '',
				email: '',
				note: ''
			},
			isCompanyLabelTxt: {
				labelOn: 'Da',
				labelOff: 'Ne'
			},
			permissionCheck: PermissionCheck
		}
	},
	watch: {
	},
	computed: {
		modalSize () {
			return 'md';
		},
		isValidEmail() {
			if (!this.client.email) {
				this.okButton.disabled = false;
				return true;
			}
			if (settings.emailRegex.test(this.client.email)) {
				this.okButton.disabled = false;
				return true;
			}
			this.okButton.disabled = true;
			return false;
		},
		emailInvalidFeedback() {
			if (this.client.email && !settings.emailRegex.test(this.client.email)) {
				return 'Nepravilna oblika emaila.';
			}
		}
	},
	created () {
	}
}
</script>

<style scoped>
.ma_pos {
	text-align: end;
}
</style>