import { format } from 'date-fns';
import settings from '@/settings.js';
export default function viewDateTime (value) {
	try {
		let result = 'dd.mm.yyyy HH:mm';
		if (value) {
			let datum = format(new Date(value), settings.viewDtFormat);
			result = datum.toString();
		}
		return result;

	} catch (ex) {
		console.error(ex.toString());
		return 'dd.mm.yyyy HH:mm';
	}
}
