import viewDate from './viewDate';
import viewDateTime from './viewDateTime';
import hrMoney from './hrMoney';
import viewTime from './viewTime';

export default {
	install (Vue) {
		Vue.filter('viewDate', viewDate);
		Vue.filter('viewDateTime', viewDateTime);
		Vue.filter('hr_money', hrMoney);
		Vue.filter('viewTime', viewTime);
	}
}
